@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400');

// mobile first
@mixin xs() {
  @media only screen and (max-width: 768px) { @content; }
}
@mixin sm() {
  @media only screen and (min-width: 768px) { @content; }
}
@mixin md() {
  @media only screen and (min-width: 992px) { @content; }
}
@mixin lg() {
  @media only screen and (min-width: 1200px) { @content; }
}

::selection {
  background: #444;
}

html {
  font-size: 62.5%; // 1rem = 10px
  background-color: #000;
}

body {
  margin: 0;
  font-size: 1.4rem; // 1.6rem = 16px
  font-family: 'Exo', sans-serif;
  color: #999;
  overflow: hidden;
}

.parallax {
  height: 100vh;
  perspective: 4.25px;
  overflow-x: hidden;
}

.parallax-group {
  height: 47.5vh;
  position: relative;
  transform-style: preserve-3d;

  &:nth-child(odd) {
    z-index: 1;
  }

  &.-header {
    position: sticky;
    top: calc(-47.5vh + 80px); // height of group + specific height to "collapse" to
    z-index: 2;
  }

  &.-footer {
    height: 80px; // specific size for footer
    background-color: #222;
    color: #999;
    font-size: 1.2rem;
    .parallax-layer {
      justify-content: center;
    }
  }
}

.parallax-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &.-layer-back {
    transform: translateZ(-2px) scale(2);
  }
  &.-layer-base {
    transform: translateZ(0);
  }
}

.content-width {
  max-width: 1200px;
  margin: auto;
}

.video-loop {
  background-color: #000;
  box-shadow: 0 0 40px 5px #000;
}
.video {
  background-color: #000;
  box-shadow: 0 0 40px 5px #000;
  overflow: hidden;

  video {
    width: 100vw;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.screenshots {
  background-color: #000;
  box-shadow: 0 0 40px 5px #000;
}

.header {
  background-image: url('./assets/website-bg-logo-1920x1080.png');
  background-size: 200%;
  background-position: 50% 80%;
  box-shadow: 0 0 40px 5px #000;
  @include sm() {
    background-size: cover;
    background-position: 50% 70%;
  }
}
.bg-forest {
  background-image: url('./assets/website-bg-forest-1920x1080.png');
  background-size: cover;
  background-position: 50%;
}
.bg-moonlight {
  background-image: url('./assets/website-bg-moonlight-1920x1080.png');
  background-size: cover;
  background-position: 50%;
}
.bg-fieldbase {
  background-image: url('./assets/website-bg-fieldbase-1920x1080.png');
  background-size: cover;
  background-position: 50%;
}

canvas.background-static {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.logo {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  height: 400px;
  z-index: 11;
  pointer-events: none;
  background-image: url('./assets/website-logo-960x540.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

nav {
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  display: none;
  @include sm() {
    display: flex;
  }

  .nav-left {
    padding-left: 20px;
  }
  .nav-right {
    padding-right: 20px;
  }

  a {
    color: #999;
    text-decoration: none;
    padding: 30px 20px;
    &:hover {
      color: #fff;
    }
  }
}

ul.screenshots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  li {
    list-style-type: none;
    width: 50%;
    height: 50%;
    cursor: pointer;
    transition: width 0.2s, height 0.2s, background-size 0.3s;
    @include sm() {
      width: 25%;
    }

    &.screenshot {
      background-size: 250%;
      background-position: 50%;
      background-repeat: no-repeat;

      &:hover {
        background-size: 280%;
      }
      &.selected {
        width: 100%;
        height: 100%;
        background-size: 120%;
      }
      &.not-selected {
        width: 0%;
        height: 0%;
      }
      &.screenshot-a {
        background-image: url('./assets/2022-07-30\ 09_19_41-Greenshot.png');
      }
      &.screenshot-b {
        background-image: url('./assets/2022-07-30\ 08_01_35-Greenshot.png');
      }
      &.screenshot-c {
        background-image: url('./assets/2022-07-30\ 11_06_47-Greenshot.png');
      }
      &.screenshot-d {
        background-image: url('./assets/2022-07-30\ 09_10_39-Greenshot.png');
        background-position: 50% 90%;
        &.selected {
          background-position: 50% 50%;
          @include sm() {
            background-position: 50% 60%;
          }
        }
        @include sm() {
          background-position: 50% 60%;
        }
      }
      &.screenshot-e {
        background-image: url('./assets/2022-07-30\ 08_33_41-Greenshot.png');
      }
      &.screenshot-f {
        background-image: url('./assets/2022-07-30\ 08_14_35-Greenshot.png');
      }
      &.screenshot-g {
        background-image: url('./assets/2022-07-30\ 08_21_10-Greenshot.png');
      }
      &.screenshot-h {
        background-image: url('./assets/2022-07-30\ 08_39_19-Greenshot.png');
      }
    }
  }
}

.description {
  padding: 0 20px;
  font-size: 1.6rem;
  text-align: center;
}

.steam-embed {
  min-width: 400px;
  width: 50vw;
  height: 190px;
}

.youtube-embed {
  width: 100vw;
  height: 100%;
}