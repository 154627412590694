@import "https://fonts.googleapis.com/css2?family=Exo:wght@400";
::selection {
  background: #444;
}

html {
  background-color: #000;
  font-size: 62.5%;
}

body {
  color: #999;
  margin: 0;
  font-family: Exo, sans-serif;
  font-size: 1.4rem;
  overflow: hidden;
}

.parallax {
  perspective: 4.25px;
  height: 100vh;
  overflow-x: hidden;
}

.parallax-group {
  transform-style: preserve-3d;
  height: 47.5vh;
  position: relative;
}

.parallax-group:nth-child(odd) {
  z-index: 1;
}

.parallax-group.-header {
  z-index: 2;
  position: sticky;
  top: calc(80px - 47.5vh);
}

.parallax-group.-footer {
  color: #999;
  background-color: #222;
  height: 80px;
  font-size: 1.2rem;
}

.parallax-group.-footer .parallax-layer {
  justify-content: center;
}

.parallax-layer {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.parallax-layer.-layer-back {
  transform: translateZ(-2px)scale(2);
}

.parallax-layer.-layer-base {
  transform: translateZ(0);
}

.content-width {
  max-width: 1200px;
  margin: auto;
}

.video-loop {
  background-color: #000;
  box-shadow: 0 0 40px 5px #000;
}

.video {
  background-color: #000;
  overflow: hidden;
  box-shadow: 0 0 40px 5px #000;
}

.video video {
  width: 100vw;
  margin-top: 0;
  margin-bottom: 0;
}

.screenshots {
  background-color: #000;
  box-shadow: 0 0 40px 5px #000;
}

.header {
  background-image: url("website-bg-logo-1920x1080.97f15f3f.png");
  background-position: 50% 80%;
  background-size: 200%;
  box-shadow: 0 0 40px 5px #000;
}

@media only screen and (width >= 768px) {
  .header {
    background-position: 50% 70%;
    background-size: cover;
  }
}

.bg-forest {
  background-image: url("website-bg-forest-1920x1080.f5044f3a.png");
  background-position: 50%;
  background-size: cover;
}

.bg-moonlight {
  background-image: url("website-bg-moonlight-1920x1080.b78a733d.png");
  background-position: 50%;
  background-size: cover;
}

.bg-fieldbase {
  background-image: url("website-bg-fieldbase-1920x1080.218883ee.png");
  background-position: 50%;
  background-size: cover;
}

canvas.background-static {
  z-index: 10;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
}

.logo {
  z-index: 11;
  pointer-events: none;
  background-image: url("website-logo-960x540.f1ef834b.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 400px;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
}

nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  display: none;
  position: sticky;
  top: 0;
}

@media only screen and (width >= 768px) {
  nav {
    display: flex;
  }
}

nav .nav-left {
  padding-left: 20px;
}

nav .nav-right {
  padding-right: 20px;
}

nav a {
  color: #999;
  padding: 30px 20px;
  text-decoration: none;
}

nav a:hover {
  color: #fff;
}

ul.screenshots-container {
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  overflow: hidden;
}

ul.screenshots-container li {
  cursor: pointer;
  width: 50%;
  height: 50%;
  list-style-type: none;
  transition: width .2s, height .2s, background-size .3s;
}

@media only screen and (width >= 768px) {
  ul.screenshots-container li {
    width: 25%;
  }
}

ul.screenshots-container li.screenshot {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 250%;
}

ul.screenshots-container li.screenshot:hover {
  background-size: 280%;
}

ul.screenshots-container li.screenshot.selected {
  background-size: 120%;
  width: 100%;
  height: 100%;
}

ul.screenshots-container li.screenshot.not-selected {
  width: 0%;
  height: 0%;
}

ul.screenshots-container li.screenshot.screenshot-a {
  background-image: url("2022-07-30 09_19_41-Greenshot.c5fa1671.png");
}

ul.screenshots-container li.screenshot.screenshot-b {
  background-image: url("2022-07-30 08_01_35-Greenshot.e898e5e0.png");
}

ul.screenshots-container li.screenshot.screenshot-c {
  background-image: url("2022-07-30 11_06_47-Greenshot.a0376d29.png");
}

ul.screenshots-container li.screenshot.screenshot-d {
  background-image: url("2022-07-30 09_10_39-Greenshot.6be40644.png");
  background-position: 50% 90%;
}

ul.screenshots-container li.screenshot.screenshot-d.selected {
  background-position: 50%;
}

@media only screen and (width >= 768px) {
  ul.screenshots-container li.screenshot.screenshot-d.selected, ul.screenshots-container li.screenshot.screenshot-d {
    background-position: 50% 60%;
  }
}

ul.screenshots-container li.screenshot.screenshot-e {
  background-image: url("2022-07-30 08_33_41-Greenshot.7b4073f4.png");
}

ul.screenshots-container li.screenshot.screenshot-f {
  background-image: url("2022-07-30 08_14_35-Greenshot.fe2df11e.png");
}

ul.screenshots-container li.screenshot.screenshot-g {
  background-image: url("2022-07-30 08_21_10-Greenshot.487ff044.png");
}

ul.screenshots-container li.screenshot.screenshot-h {
  background-image: url("2022-07-30 08_39_19-Greenshot.129b55c6.png");
}

.description {
  text-align: center;
  padding: 0 20px;
  font-size: 1.6rem;
}

.steam-embed {
  width: 50vw;
  min-width: 400px;
  height: 190px;
}

.youtube-embed {
  width: 100vw;
  height: 100%;
}
/*# sourceMappingURL=index.b7fdfc63.css.map */
